$(document).ready(function () {
	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--eventos[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');
		var initialSlide = $slider.find('.swiper-slide').length;

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.2,
			spaceBetween: 16,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			autoplay: false,
			initialSlide: initialSlide,
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
			},
			breakpoints: {
				480: {
					slidesPerView: 1.4,
					centeredSlides: true,
				},
				600: {
					slidesPerView: 2.1,
				},
				1024: {
					slidesPerView: 3.2,
					allowTouchMove: true,
				}
			}
		});

		swiper_slider.loopDestroy();
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--bitacora[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');
		var initialSlide = $slider.find('.swiper-slide').length;

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.2,
			spaceBetween: 16,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			initialSlide: initialSlide,
			autoplay: false,
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
			},
			breakpoints: {
				600: {
					slidesPerView: 1.4,
					centeredSlides: true,
				},
				1024: {
					slidesPerView: 2.2,
					allowTouchMove: false,
					draggable: false,
				}
			}
		});

		swiper_slider.loopDestroy();
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--testimonios:not(.swiper--carousel--testimonios--pequeno)[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.2,
			spaceBetween: 32,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			autoplay: false,
			initialSlide: 1,
			centeredSlides: true,
			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				// type: 'custom',
				clickable: true,
				renderBullet: function (index, className) {
					return '<button class="' + className + '"><span>' + (index + 1) + '</span></button>';
				}
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				600: {
					slidesPerView: 1.4,
					centeredSlides: true,
				},
				850: {
					slidesPerView: 2.2,
				},
				1024: {
					slidesPerView: 3.2,
					allowTouchMove: false,
					draggable: false
				}
			}
		});

		swiper_slider.loopDestroy();
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--testimonios--pequeno[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.2,
			spaceBetween: 32,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			autoplay: false,
			centeredSlides: true,

			pagination: {
				el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
				// type: 'custom',
				clickable: true,
				renderBullet: function (index, className) {
					return '<button class="' + className + '"><span>' + (index + 1) + '</span></button>';
				}
			},
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
			},
			breakpoints: {
				600: {
					slidesPerView: 1.4,
					centeredSlides: true,
				},
				850: {
					slidesPerView: 2.2,
					centeredSlides: false,
					pagination: {
						el: '[data-swiper-parent="' + slider_parent + '"] .swiper-pagination',
						// type: 'custom',
						clickable: true,
						renderBullet: function (index, className) {

							if ((index + 1) == this.snapGrid.length) {
								return '<button class="' + className + '"><span>' + (index + 1) + '</span></button><button class="' + className + '"><span>' + (index + 2) + '</span></button>';
							} else {
								return '<button class="' + className + '"><span>' + (index + 1) + '</span></button>';
							}
						}
					},
				}
			},
			on: {
				// snapGridLengthChange:function(){
				// 	if( this.snapGrid.length != this.slidesGrid.length ){
				// 		this.snapGrid = this.slidesGrid.slice(0);
				// 	}
				// }
				reachEnd: function () {
					this.snapGrid = [...this.slidesGrid];
				},
			}
		});

		swiper_slider.loopDestroy();
	});

	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--nav[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');

		var slidenav = new Swiper(slider, {
			slidesPerView: 2.2,
			spaceBetween: 8,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			slideActiveClass: 'active',
			centeredSlides: true,
			breakpoints: {
				600: {
					slidesPerView: 3,
					centeredSlides: true,
				}
			}
		});

		slidenav.loopDestroy();

		$('.swiper--carousel--nav .swiper-slide').on('click', function () {
			slidenav.slideTo($(this).data('child'), slider_speed, true);
		});
	});
	//documentos en zona publico
	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--documentos[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');
		var initialSlide = $slider.find('.swiper-slide').length - 3;
		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.2,
			spaceBetween: 16,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			autoplay: false,
			initialSlide: initialSlide,
			setWrapperSize: true,
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
			},
			breakpoints: {
				600: {
					slidesPerView: 2.1,
					centeredSlides: false,
				},
				850: {
					slidesPerView: 3.2,
					centeredSlides: false,
				},
				1024: {
					slidesPerView: 4.2,
					allowTouchMove: false,
					draggable: false,
					centeredSlides: false,
				}
			}
		});

		swiper_slider.loopDestroy();
	});

	//big proyecto en home privada
	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--full[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');
		var initialSlide = $slider.find('.swiper-slide').length;
		var swiper_slider = new Swiper(slider, {
			slidesPerView: 1.1,
			spaceBetween: 10,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			initialSlide: initialSlide,
			autoplay: false,
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
			},
		});

		swiper_slider.loopDestroy();
	});
	//recursos en home privada
	jQuery.each(jQuery('.swiper--carousel.swiper--carousel--recursos[data-module="swiper"]'), function () {
		var slider = this;
		var $slider = $(slider);
		var slider_speed = $slider.data('speed') || 1000;
		var slider_parent = $slider.data('swiper-heritage');
		var initialSlide = $slider.find('.swiper-slide').length;
		var swiper_slider = new Swiper(slider, {
			slidesPerView: 3.15,
			spaceBetween: 16,
			effect: 'slide',
			loop: false,
			speed: slider_speed,
			initialSlide: initialSlide,
			autoplay: false,
			navigation: {
				nextEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-prev',
				prevEl: '[data-swiper-parent="' + slider_parent + '"] .swiper-button-next',
			},
		});

		swiper_slider.loopDestroy();
	});
});